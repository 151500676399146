import { putCache, requestToServerCacheUpdate } from '../utils/LocalApi'
import { fetchBaseQuery } from './query_utils'
import { apiAxios } from '../services/api'
import enviroment from '../enviroment'

interface Datum {
  id: number;
  name: string;
  units: Unit[];
  email: null | string;
  cpf: null | string;
  send_email: boolean;
  deleted: null;
  created_at: string;
  updated_at: string;
  status: number;
  person_role: number;
  type_person: number;
  rg: null | string;
  doc_secondary: null;
  nationality: string;
  birthday: null | string;
  gender: string;
  kinship: null | string;
  phone: null;
  phone_commercial: null;
  mobile1: null | string;
  mobile2: null;
  profession: null;
  marital: null;
  same_address_condominium: boolean;
  address: null;
  district: null;
  city: null;
  state: null;
  cep: null;
  country: string | null;
  contact_name: null;
  contact_phone: null;
  contact_kinship: null;
  uuid: string;
  id_in_equipment: null;
  id_old_guest: null;
  description: null;
  blocked: boolean;
  blocked_until: null;
  face_validated: boolean;
  face_deleted: null;
  updated_by: number | null;
  deleted_by: null;
  slave_access_group: null;
  file: number | null;
  access_group_id: number | null;
  parent_id: number | null;
  updated_by_id: number | null;
  deleted_by_id: null;
  user_id: number | null;
  slave_access_group_id: null;
  file_id: number | null;
  photo?: {
    file: string
  }
}

interface Unit {
  id: number;
  unit_id: number;
  code: string;
  status: number;
  type: number;
  area: number;
  residents: null;
  street: null;
  with_block: string;
  block_id: number;
  pivot_status: number;
  pivot_type: number;
  static_debtor: boolean;
}

const cache_key = 'PEOPLE'

const fetchQuery = fetchBaseQuery<Datum>({
  key: cache_key,
  url: 'admin/people?include[]=units&include[]=photo&sort[]=id&limit=200000',
  stale_time: 60 * 30
})

export const people = {
  allCached: async (force = false) => {
    let data = await fetchQuery.allCached(force)

    try {
      requestToServerCacheUpdate('people')
    } catch (e) { }

    try {
      // Add backwards integration with old server version
      putCache('peoples', data, { timeout: 40 * 1000 })
    } catch (e) { }

    return data
  },

  idsWithUnitsActive: async () => {
    const { data } = await apiAxios.get(enviroment().apiUrl + `admin/people/ids_with_units_active`)
    return data

  },

  accessControlFaceEnroll: async (person_id: string) => {
    const { data } = await apiAxios.get(enviroment().apiUrl + `admin/people/${person_id}/access_control_face_enroll?is_generic=true`)
    return data
  },

  accessControlFaceDelete: async (person_id: string) => {
    const { data } = await apiAxios.get(enviroment().apiUrl + `admin/people/${person_id}/access_control_face_delete?is_generic=true`)
    return data
  },

  validateDoc: async (doc: string) => {
    const { data } = await apiAxios.post(enviroment().apiUrl + `admin/people/validate_doc`, { cpf: doc })
    return data
  }
}