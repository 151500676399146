import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Input, Label, Modal, Tab } from 'semantic-ui-react'
import { qs } from '../../../queries'
import { push_toast } from '../../../utils/common'
import { isPrivate } from '../../../utils/form'
import { removeAccents } from '../../../utils/Formatters'
import { gridDefaults } from '../../../utils/gridDefaults'
import PrivacyData from '../../../utils/PrivacyData'
import { person_actions } from '../people'
import PeopleFormPage from './PeopleFormPage'
import { getCondoLabors } from '../../../utils/DataService'
import { useAccessDeviceSettingsQuery } from '../../../hooks'

const PeopleListModalPage = () => {
  const dispatch = useDispatch()
  const show_modal = useSelector((state: any) => state.people.showModal)
  const { data: access_control_settings } = useAccessDeviceSettingsQuery()

  const residents_query = useQuery<any[]>('people.allCached', () => qs.people.allCached(true), {
    onSuccess: () => {
      push_toast('Dados sincronizados', 'success')
    }
  })
  const residents_query_data = residents_query?.data ?? []

  const labors_query = useQuery<any[]>('labors.list', () => getCondoLabors())

  const [quick_filter_text, setQuickFilterText] = useState<any>(null)
  const [pendent_face_validation, setPendentFaceValidation] = useState(false)
  const [active_index, setActiveIndex] = useState(0)

  const getObject = (id: any) => dispatch(person_actions.get(id))
  const modalObject = (show: boolean) => dispatch(person_actions.modal(show))

  const handleEdit = (id) => {
    getObject(id)
    modalObject(true)
  }

  const closeModalPerson = () => {
    modalObject(false)
    dispatch(person_actions.current({}))
  }

  const onQuickFilterText = (e) => {
    let ev = removeAccents(e.target)
    setQuickFilterText(ev)
  }

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit()
  }

  const residents_columns_def = [
    {
      headerName: '#',
      field: 'obj_number',
      width: 40,
      filter: 'text'
    },
    {
      headerName: '#',
      field: 'id_in_equipment',
      width: 40,
      filter: 'text'
    },
    {
      headerName: 'Nome',
      field: 'name',
      width: 130,
      filter: 'text',
      getQuickFilterText: removeAccents
    },
    {
      headerName: 'CPF',
      field: 'cpf',
      width: 50,
      filter: 'text',
      valueFormatter: (params) => {
        return isPrivate('person__cpf') ? PrivacyData.maskPhone(params.value) : params.value
      }
    },
    {
      headerName: 'Unidades',
      field: 'units',
      width: 80,
      filter: 'text',
      valueGetter: (params) => params.data.units.map((uni) => uni.with_block)
    },
    {
      headerName: '',
      width: 100,
      suppressResize: true,
      suppressMovable: true,
      suppressSorting: true,
      suppressMenu: true,
      suppressFilter: true,
      suppressSizeToFit: true,
      cellRendererFramework: (params) => {
        return (
          <button className="ui button basic compact green floated right" onClick={() => handleEdit(params.data.id)}>
            Editar
          </button>
        )
      }
    }
  ]

  const labors_condo_columns_def = [
    {
      headerName: '#',
      field: 'obj_number',
      width: 40,
      filter: 'text'
    },
    {
      headerName: '#',
      field: 'id_in_equipment',
      width: 40,
      filter: 'text'
    },
    {
      headerName: 'Nome',
      field: 'name',
      width: 130,
      filter: 'text',
      getQuickFilterText: removeAccents
    },
    {
      headerName: 'CPF',
      field: 'cpf',
      width: 50,
      filter: 'text',
      valueFormatter: (params) => {
        return isPrivate('person__cpf') ? PrivacyData.maskPhone(params.value) : params.value
      }
    },
    {
      headerName: '',
      width: 100,
      suppressResize: true,
      suppressMovable: true,
      suppressSorting: true,
      suppressMenu: true,
      suppressFilter: true,
      suppressSizeToFit: true,
      cellRendererFramework: (params) => {
        return (
          <button className="ui button basic compact green floated right" onClick={() => handleEdit(params.data.id)}>
            Editar
          </button>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch(person_actions.current({}))
  }, [])

  return (
    <React.Fragment>
      <Modal size={'fullscreen'} open={true}>
        <Modal.Header>
          <Tab
            activeIndex={active_index}
            menu={{ inverted: true, attached: false, tabular: false }}
            onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex as any)}
            panes={[
              { menuItem: 'Condôminos', render: () => <></> },
              { menuItem: 'Colaboradores Condôminio', render: () => <></> },
            ]}
          />

          &nbsp;

          <div className="page-modal-header">
            <Input icon="search" size="small" onChange={onQuickFilterText} placeholder="Filtrar..."/>

            &nbsp;&nbsp;&nbsp;&nbsp;

            {active_index === 0 &&
              <div>
                <Checkbox
                  toggle size="small" label="Filtrar Faces pendentes de validação"
                  checked={pendent_face_validation}
                  onChange={() => setPendentFaceValidation(!pendent_face_validation)}
                />
                &nbsp;
                <Label circular color="red">
                  {residents_query_data && residents_query_data.filter(i => i.face_validated === false && i.file_id).length}
                </Label>
              </div>}

            <div className="page-modal-buttons">
              {active_index === 0 &&
                <Button primary onClick={() => residents_query.refetch()} loading={residents_query.isFetching} disabled={residents_query.isFetching}>Sincronizar
                  Dados</Button>}
              <Button negative onClick={() => dispatch(person_actions.modal_list(false))}>Fechar</Button>
            </div>
          </div>
        </Modal.Header>

        <Modal.Content scrolling>
          <div style={{ width: '100%' }} className="ag-theme-material">
            {active_index === 0 &&
              <AgGridReact
                gridOptions={gridDefaults.adminGrid}
                columnDefs={residents_columns_def}
                rowData={
                  pendent_face_validation && residents_query_data
                    ? residents_query_data.filter(i => i.face_validated === false && i.file_id)
                    : residents_query_data
                }
                quickFilterText={quick_filter_text}
                onGridReady={onGridReady}
                getRowNodeId={(data) => data.id}/>}
            {active_index === 1 &&
              <AgGridReact
                gridOptions={gridDefaults.adminGrid}
                columnDefs={labors_condo_columns_def}
                rowData={
                  pendent_face_validation && labors_query.data
                    ? labors_query.data.filter(i => i.face_validated === false && i.file_id)
                    : labors_query.data
                }
                quickFilterText={quick_filter_text}
                onGridReady={onGridReady}
                getRowNodeId={(data) => data.id}/>}
          </div>
        </Modal.Content>
      </Modal>

      {show_modal &&
        <React.Fragment>
          <Modal size={'fullscreen'} open={true}>
            <Modal.Header>
              <div className="page-modal-header">
                Editar Morador

                <div className="page-modal-buttons">
                  <Button negative onClick={closeModalPerson}>Fechar</Button>
                </div>
              </div>
            </Modal.Header>
            <Modal.Content scrolling>
              <PeopleFormPage access_control_settings={access_control_settings}/>
            </Modal.Content>
          </Modal>

        </React.Fragment>}
    </React.Fragment>
  )

}

export default React.memo(PeopleListModalPage)
